:root {
  --bn--payment-status: #eee;
  --bn--payment-status--next-up: #bdbdbd;
  --bn--payment-status--immediate: #ffb547;
  --bn--payment-status--paid: #4caf50;
  --bn--payment-status--overdue: #f44336;
}

.gift-message {
  --checkbox-accent: #333333;
  --message-color: #333333;
  --border-color: #cccccc;
  --padding: 1.6rem;
  --checkbox-size: 1.6rem;
  background: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  container: addMessage/inline-size;
}

.gift-message summary:focus-visible {
  outline: var(--bn--focus-outline);
  outline-offset: var(--bn--focus-outline-offset);
}

/* Ensures the webkit details marker stays hidden */
.gift-message summary::-webkit-details-marker {
  display: none;
}

.gift-message__toggle {
  align-items: flex-start;
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  display: grid;
  font-size: 1.4rem;
  letter-spacing: 0.15px;
  line-height: 1.357;
  gap: 0 var(--padding);
  grid-template-columns: var(--checkbox-size) 1fr var(--checkbox-size);
  list-style: none;
  padding: var(--padding);
  text-align: left;
}

[open] .gift-message__toggle {
  border-bottom-color: var(--border-color);
}

.gift-message__checkbox-wrapper {
  align-items: center;
  display: flex;
  margin-top: var(--padding);
  margin-left: var(--padding);
}

.gift-message__text {
  color: var(--message-color);
  display: block;
  height: 85%;
  margin-top: 1px;
  overflow: auto;
  outline: none;
  padding: 8px;
}
.gift-message__text span {
  background: #ffa0a0;
}

.gift-message__checkbox {
  background: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  display: flex;
  height: var(--checkbox-size);
  width: var(--checkbox-size);
}
.gift-message__checkbox svg {
  display: block;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
:where(.is-checked, [open]) .gift-message__checkbox {
  background: var(--checkbox-accent);
  border-color: var(--checkbox-accent);
}
:where(.is-checked, [open]) .gift-message__checkbox svg {
  opacity: 1;
  visibility: visible;
}

.gift-message__icon {
  --offset: calc(-1 * var(--padding));
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  height: 4.4rem;
  margin-left: var(--offset);
  margin-top: var(--offset);
  padding: 0;
  width: 4.4rem;
}
.gift-message__icon svg {
  display: block;
  margin: auto;
}

.gift-message__button {
  align-items: flex-start;
}

.gift-message__caret {
  align-items: center;
  justify-content: center;
  transform-origin: center;
}
[open] .gift-message__caret {
  transform: rotate(180deg);
}

.gift-message__header :last-child {
  margin-bottom: 0;
}

.gift-message__header .customize-step-label {
  line-height: inherit;
  margin-bottom: 0.5rem;
}

.gift-message__instructions {
  color: #3F3F3F;
}

.gift-message__drawer {
  color: var(--message-color);
  padding: var(--padding);
}

.gift-message__drawer > :last-child {
  margin-bottom: 0;
}

@supports (container: size) {
  @container (min-width: 480px) {
    .gift-message__drawer {
      padding-left: calc(var(--padding) * 2 + var(--checkbox-size));
    }
  }
}
@supports not (container: size) {
  @media (min-width: 768px) {
    .gift-message__drawer {
      padding-left: calc(var(--padding) * 2 + var(--checkbox-size));
    }
  }
}
/* Sentiment Buttons */
.gift-message__sentiments {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.sentiment-btn {
  --active-color: #3B873E;
  background: #FFFFFF;
  border: 1px solid #B6B6B6;
  border-radius: 4px;
  color: #29223a;
  padding: 0.6rem 1.6rem;
  margin: 0;
  white-space: nowrap;
}

.sentiment-btn:where(.active, :hover, :focus-visible) {
  border: 1px solid var(--active-color);
  color: var(--active-color);
}

.occasion-control-btns button:focus-visible {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}

.sentiments-header-block {
  display: inline-block;
}

.occasion-arrow-back-block {
  float: right;
  margin-top: 5px;
}

.occasion-arrow-back {
  cursor: pointer;
}

.suggest-message,
.suggest-message-container {
  margin-bottom: 16px;
}

.suggest-message-btn {
  background: none;
  box-shadow: none;
  color: rgba(9, 1, 28, 0.8705882353);
  cursor: pointer;
  border: none;
}

.suggest-message-btn:hover {
  color: rgba(9, 1, 28, 0.8705882353);
}

.sentiments-block {
  border: 1px solid #BDBDBD;
  border-radius: 0 0 4px 4px;
  padding: 12px 16px 32px 24px;
  margin-top: -3px;
  background-color: #F2F2F2;
}

.sentiments-block button,
.sentiment-buttons button {
  font-size: 14px;
  font-weight: var(--bn--font-normal);
  line-height: 17px;
}

.scrollbox {
  overflow: auto;
}

.current-occasion-message {
  font-family: "Kalam", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #424242;
  padding: 16px 0;
}

.occasion-control-btns {
  display: flex;
}

.previous-sentiment {
  text-align: left;
  max-width: 44px;
}

.next-sentiment {
  text-align: right;
  max-width: 44px;
}

.select-sentiment {
  width: 174px;
  text-align: center;
  margin: auto 16px;
}

.select-sentiment-message-btn {
  width: 100%;
  padding: 7px 0;
  background: #FFFFFF;
  border: 1px solid #61A253;
  border-radius: 4px;
}

.select-sentiment-message-btn:hover {
  color: #3B873E;
}

.occasion-arrow-back i {
  border: solid #696475;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.store-contact {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.8rem;
  gap: 2.5rem 0;
  line-height: 1.3338;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.store-contact * {
  font-size: inherit;
}
.store-contact__column {
  align-items: center;
  display: flex;
  justify-content: center;
}
.store-contact__details > * + * {
  margin-top: 1.5rem;
}
.store-contact__details a {
  color: inherit;
}
.store-contact__details a:hover {
  text-decoration: underline;
}
.store-contact__detail {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: var(--bn--font-medium);
  gap: 0.5rem 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .store-contact__detail {
    text-align: left;
    flex-direction: row;
  }
}
.store-contact .fa {
  --size: 4rem;
  border: 1px #CCC solid;
  border-radius: 50%;
  height: var(--size);
  padding: 10px;
  text-align: center;
  width: var(--size);
}

.store-map__image {
  border-radius: 4px;
  display: block;
  height: auto;
  width: 100%;
}

.store-map__container iframe {
  border: none;
  height: 300px;
  width: 100%;
  border-radius: 8px;
}

.store-hours {
  display: inline-grid;
  grid-template-columns: max-content 1fr;
  gap: 0.5em 1.38em;
  white-space: nowrap;
}

.store-about {
  margin-top: 16px;
}

.about-us-nlp h2.about-us-nlp__header {
  font-size: 20px;
  font-weight: var(--bn--font-demibold) !important;
}

:root {
  --bn--button--background-color: #2c2c2c;
  --bn--button--color: #fff;
  --bn--button--border-color: var(--bn--button--color);
  --bn--button--border-radius: 4px;
  --bn--button--secondary--background-color: var(--bn--button--color, #fff);
  --bn--button--secondary--color: var(--bn--button--background-color, #2c2c2c);
  --bn--button--secondary--border-color: var(--bn--button--secondary--color);
  --bn--button--focus--outline-color: #3b82f6;
}

.button {
  align-items: center;
  background-color: var(--bn--button--background-color);
  border: 1px solid var(--bn--button--border-color);
  border-radius: var(--bn--button--border-radius);
  color: var(--bn--button--color);
  display: flex;
  font-family: "Avenir", sans-serif;
  font-size: 14px;
  font-weight: var(--bn--font-medium);
  justify-content: center;
  padding: 8px;
  transition: background-color ease-in-out 0.125s, border-color ease-in-out 0.125s;
}
.button:hover, .button:active {
  background-color: color-mix(in srgb, var(--bn--button--background-color), #fff 15%);
  border-color: color-mix(in srgb, var(--bn--button--border-color), #fff 15%);
  color: var(--bn--button--color);
  text-decoration: none !important;
}
.button:focus {
  color: var(--bn--button--color);
  outline: 2px solid var(--bn--button--focus--outline-color);
  outline-offset: 2px;
}
.button--secondary {
  background-color: var(--bn--button--secondary--background-color);
  border: 1px solid var(--bn--button--secondary--border-color);
  color: var(--bn--button--secondary--color);
}
.button--secondary:hover, .button--secondary:active {
  background-color: color-mix(in srgb, var(--bn--button--secondary--background-color), var(--bn--button--secondary--color) 5%);
  border-color: color-mix(in srgb, var(--bn--button--secondary--border-color), var(--bn--button--secondary--color) 15%);
  color: var(--bn--button--secondary--color);
}
.button--secondary:focus {
  color: var(--bn--button--secondary--color);
}

.payment-status {
  background-color: var(--bn--payment-status--next-up);
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}
.payment-status--paid {
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin-top: -2px;
}
.payment-status--canceled {
  display: none;
}
.payment-status--overdue, .payment-status--immediate {
  position: relative;
}
@media (prefers-reduced-motion: no-preference) {
  .payment-status--overdue:after, .payment-status--immediate:after {
    animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
    width: 10px;
  }
}
.payment-status--overdue {
  background-color: var(--bn--payment-status--overdue);
}
@media (prefers-reduced-motion: no-preference) {
  .payment-status--overdue:after {
    background-color: var(--bn--payment-status--overdue);
  }
}
.payment-status--immediate {
  background-color: var(--bn--payment-status--immediate);
}
@media (prefers-reduced-motion: no-preference) {
  .payment-status--immediate:after {
    background-color: var(--bn--payment-status--immediate);
  }
}