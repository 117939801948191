.gift-message {
  --checkbox-accent: #333333;
  --message-color: #333333;
  --border-color: #cccccc;
  --padding: 1.6rem;
  --checkbox-size: 1.6rem;
  background: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  container: addMessage / inline-size;
}

.gift-message summary:focus-visible {
  outline: var(--bn--focus-outline);
  outline-offset: var(--bn--focus-outline-offset);
}

/* Ensures the webkit details marker stays hidden */
.gift-message summary::-webkit-details-marker {
  display: none;
}

.gift-message__toggle {
  align-items: flex-start;
  appearance: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  box-shadow: none;
  cursor: pointer;
  display: grid;
  font-size: 1.4rem;
  letter-spacing: 0.15px;
  line-height: 1.357;
  gap: 0 var(--padding);
  grid-template-columns: var(--checkbox-size) 1fr var(--checkbox-size);
  list-style: none;
  padding: var(--padding);
  text-align: left;
}

[open] .gift-message__toggle {
  border-bottom-color: var(--border-color);
}

.gift-message__checkbox-wrapper {
  align-items: center;
  display: flex;
  margin-top: var(--padding);
  margin-left: var(--padding);
}

.gift-message__text {
  color: var(--message-color);
  display: block;
  height: 85%;
  margin-top: 1px;
  overflow: auto;
  outline: none;
  padding: 8px;

  span {
    background: #ffa0a0;
  }
}

.gift-message__checkbox {
  background: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  display: flex;
  height: var(--checkbox-size);
  width: var(--checkbox-size);

  svg {
    display: block;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  :where(.is-checked, [open]) & {
    background: var(--checkbox-accent);
    border-color: var(--checkbox-accent);

    svg {
      opacity: 1;
      visibility: visible;
    }
  }
}

.gift-message__icon {
  --offset: calc(-1 * var(--padding));
  background: none;
  border: none;
  box-shadow: none;
  display: flex;
  height: 4.4rem;
  margin-left: var(--offset);
  margin-top: var(--offset);
  padding: 0;
  width: 4.4rem;

  svg {
    display: block;
    margin: auto;
  }
}

.gift-message__button {
  align-items: flex-start;
}

.gift-message__caret {
  align-items: center;
  justify-content: center;
  transform-origin: center;

  [open] & {
    transform: rotate(180deg);
  }
}


.gift-message__header :last-child {
  margin-bottom: 0;
}

.gift-message__header .customize-step-label {
  line-height: inherit;
  margin-bottom: .5rem;
}

.gift-message__instructions {
  color: #3F3F3F;
}

.gift-message__drawer {
  color: var(--message-color);
  padding: var(--padding);
}

.gift-message__drawer > :last-child {
  margin-bottom: 0;
}

@supports (container: size) {
  @container (min-width: 480px) {
    .gift-message__drawer {
      padding-left: calc(var(--padding) * 2 + var(--checkbox-size));
    }
  }
}

@supports not (container: size) {
  @media (min-width: 768px) {
    .gift-message__drawer {
      padding-left: calc(var(--padding) * 2 + var(--checkbox-size));
    }
  }
}

/* Sentiment Buttons */

.gift-message__sentiments {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.sentiment-btn {
  --active-color: #3B873E;
  background: #FFFFFF;
  border: 1px solid #B6B6B6;
  border-radius: 4px;
  color: #29223a;
  padding: .6rem 1.6rem;
  margin: 0;
  white-space: nowrap;
}

.sentiment-btn:where(.active, :hover, :focus-visible) {
  border: 1px solid var(--active-color);
  color: var(--active-color);
}

.occasion-control-btns button:focus-visible {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}


.sentiments-header-block {
  display: inline-block;
}

.occasion-arrow-back-block {
  float: right;
  margin-top: 5px;
}

.occasion-arrow-back {
  cursor: pointer;
}

.suggest-message,
.suggest-message-container {
  margin-bottom: 16px;
}

.suggest-message-btn {
  background: none;
  box-shadow: none;
  color: #09011CDE;
  cursor: pointer;
  border: none;
}

.suggest-message-btn:hover {
  color: #09011CDE;
}

.sentiments-block {
  border: 1px solid #BDBDBD;
  border-radius: 0 0 4px 4px;
  padding: 12px 16px 32px 24px;
  margin-top: -3px;
  background-color: #F2F2F2;
}

.sentiments-block button,
.sentiment-buttons button {
  font-size: 14px;
  font-weight: var(--bn--font-normal);
  line-height: 17px;
}

.scrollbox {
  overflow: auto;
}

.current-occasion-message {
  font-family: 'Kalam', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #424242;
  padding: 16px 0;
}

.occasion-control-btns {
  display: flex;
}

.previous-sentiment {
  text-align: left;
  max-width: 44px;
}

.next-sentiment {
  text-align: right;
  max-width: 44px;
}

.select-sentiment {
  width: 174px;
  text-align: center;
  margin: auto 16px;
}

.select-sentiment-message-btn {
  width: 100%;
  padding: 7px 0;
  background: #FFFFFF;
  border: 1px solid #61A253;
  border-radius: 4px;
}

.select-sentiment-message-btn:hover {
  color: #3B873E;
}

.occasion-arrow-back i {
  border: solid #696475;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
