.store-contact {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.8rem;
    gap: 2.5rem 0;
    line-height: 1.3338;
    margin-bottom: 4rem;
    margin-top: 4rem;

    * {
        font-size: inherit;
    }

    &__column {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__details {
        > * + * {
            margin-top: 1.5rem;
        }

        a {
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__detail {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-weight: var(--bn--font-medium);
        gap: .5rem 1rem;
        text-align: center;

        @media (min-width: 768px) {
            text-align: left;
            flex-direction: row;
        }
    }

    .fa {
        --size: 4rem;
        border: 1px #CCC solid;
        border-radius: 50%;
        height: var(--size);
        padding: 10px;
        text-align: center;
        width: var(--size);
    }
}

.store-map__image {
    border-radius: 4px;
    display: block;
    height: auto;
    width: 100%;
}

.store-map__container iframe {
    border: none;
    height: 300px;
    width: 100%;
    border-radius: 8px;
}

.store-hours {
    display: inline-grid;
    grid-template-columns: max-content 1fr;
    gap: .5em 1.38em;
    white-space: nowrap;
}

.store-about {
    margin-top: 16px;
}

.about-us-nlp {
    h2#{&}__header {
        font-size: 20px;
        font-weight: var(--bn--font-demibold) !important;
    }
}
