:root {
    --bn--button--background-color: #2c2c2c;
    --bn--button--color: #fff;
    --bn--button--border-color: var(--bn--button--color);
    --bn--button--border-radius: 4px;

    --bn--button--secondary--background-color: var(--bn--button--color, #fff);
    --bn--button--secondary--color: var(--bn--button--background-color, #2c2c2c);
    --bn--button--secondary--border-color: var(--bn--button--secondary--color);

    --bn--button--focus--outline-color: #3b82f6;
}

.button {
    align-items: center;
    background-color: var(--bn--button--background-color);
    border: 1px solid var(--bn--button--border-color);
    border-radius: var(--bn--button--border-radius);
    color: var(--bn--button--color);
    display: flex;
    font-family: 'Avenir', sans-serif;
    font-size: 14px;
    font-weight: var(--bn--font-medium);
    justify-content: center;
    padding: 8px;
    transition: background-color ease-in-out .125s,
        border-color ease-in-out .125s;

    &:hover,
    &:active {
        background-color: color-mix(in srgb, var(--bn--button--background-color), #fff 15%);
        border-color: color-mix(in srgb, var(--bn--button--border-color), #fff 15%);
        color: var(--bn--button--color);
        text-decoration: none !important;
    }

    &:focus {
        color: var(--bn--button--color);
        outline: 2px solid var(--bn--button--focus--outline-color);
        outline-offset: 2px;
    }

    &--secondary {
        background-color: var(--bn--button--secondary--background-color);
        border: 1px solid var(--bn--button--secondary--border-color);
        color: var(--bn--button--secondary--color);

        &:hover,
        &:active {
            background-color: color-mix(in srgb, var(--bn--button--secondary--background-color), var(--bn--button--secondary--color) 5%);
            border-color: color-mix(in srgb, var(--bn--button--secondary--border-color), var(--bn--button--secondary--color) 15%);
            color: var(--bn--button--secondary--color);
        }

        &:focus {
            color: var(--bn--button--secondary--color);
        }
    }
}
