.payment-status {
    background-color: var(--bn--payment-status--next-up);
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;

    &--paid {
        background-color: transparent;
        width: 20px;
        height: 20px;
        margin-top: -2px;
    }

    &--canceled {
        display: none;
    }

    &--overdue,
    &--immediate {
        position: relative;

        @media (prefers-reduced-motion: no-preference) {
            &:after {
                animation: ping 1.5s cubic-bezier(0, 0, .2, 1) infinite;
                border-radius: 50%;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 10px;
                width: 10px;
            }
        }
    }

    &--overdue {
        background-color: var(--bn--payment-status--overdue);

        @media (prefers-reduced-motion: no-preference) {
            &:after {
                background-color: var(--bn--payment-status--overdue);
            }
        }
    }

    &--immediate {
        background-color: var(--bn--payment-status--immediate);

        @media (prefers-reduced-motion: no-preference) {
            &:after {
                background-color: var(--bn--payment-status--immediate);
            }
        }
    }
}
